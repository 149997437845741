import axios from "axios";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import { useLanguage } from "./LanguageContext";
import { API_BASE_URL } from "./config";
const ApplyNow = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
 
  const [loader, setLoader] = useState(false)
  const [branch, setBranch] = useState()
  const [query, setQuery] = useState();
  const [otpBtn, setOtpBtn] = useState(false);
  const [details, setDetails] = useState({});
  const [subBtn, setSubBtn] = useState(true);
  const [otp, setOtp] = useState("");
  const [fillOtp, setFillOtp] = useState("");
  const [criptOtp, setCriptOtp] = useState();

  const { selectedLanguage } = useLanguage();


  const userApplyForm = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  useEffect(() => {
    setCriptOtp(md5(fillOtp));
  }, [fillOtp]);

  useEffect(() => {
    const fillOtp = { ...details };
    fillOtp.OTP = criptOtp;
    setDetails(fillOtp);
  }, [criptOtp]);

  const queryFill = (e) => {
    const query = { ...details };
    query.PageName = e.target.value;
    setDetails(query);
  };

  useEffect(() => {
    if (
      details.Name &&
      details.Name != "" &&
      details.Email &&
      details.Email != "" &&
      details.Phone &&
      details.Phone != "" &&
      details.Branch &&
      details.Branch != "" &&
      details.Comments &&
      details.Comments != "" &&
      details.PageName &&
      details.PageName != "" &&
      fillOtp
    ) {
      setSubBtn(false);
    } else {
      setSubBtn(true);
    }
  }, [details, fillOtp]);


  const date = new Date()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  const dateFomate = formatDate(date)
  const key = 'KDCC'
  const token = md5(dateFomate + key)

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          alert(error.message);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.')
     
    }
  };

  useEffect(()=>{
    getLocation()
  },[])


  useEffect(() => {
    if (location.latitude && location.longitude) {
    axios.post(`${API_BASE_URL}/nearestbranchlist`, { lang: selectedLanguage,latitude:location?.latitude,longitude: location?.longitude }, { headers: { 'X-Custom-Token': token } })
        .then((res) => {
          setBranch(res?.data?.data)
        })
        .catch((err) => {
            // console.log(err);  
        })}
}, [selectedLanguage, token,location])

  const sendOtp = () => {
    setLoader(true)
    axios
      .post(`${API_BASE_URL}/otp-apply-now-forms`, { Phone: details.Phone }, { headers: { 'X-Custom-Token': token } })
      .then((response) => {
        if (response.data.success == true) {
          setOtpBtn(true);
    setLoader(false)

          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${response?.data?.message}`,
          });
        }
      })
      .catch((error) => {
    setLoader(false)

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error?.response?.data?.message?.Phone?.[0]}`,
        });
      });
  };

  const submitDetails = () => {
    axios
      .post(
        `${API_BASE_URL}/apply-now-forms`, details, { headers: { 'X-Custom-Token': token } })
      .then((response) => {
        if (response.data.success == true) {
          setLoader(false)  
          Swal.fire({
            icon: "success",
            title: `Reference no. - ${response.data.refrenceNo} `,
            html:
              `<b>${response.data.message}</b>. `
          });
          setOtpBtn(false);
          setDetails({
            Name: " ",
            Email: " ",
            Phone: " ",
            PageName: " ",
            Branch: " ",
            Comments: " ",
          });
          setFillOtp("");
        } else {
          setLoader(false)  
          Swal(response.data.responseMessage);
        }
      })
      .catch((error) => {
        setLoader(false) 

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error?.response?.data?.message?.Phone?.[0]}`,
        });
      });
  };

  const branchFill = (e) => {
    const query = { ...details };
    query.Branch = e.target.value;
    setDetails(query);
  };

  return (
    <div>
         {
        loader &&
        <div class='loader' >
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      }
          

      <Helmet>
        <meta charset="utf-8" />
        <title>{selectedLanguage == "en" ? "Ask your question or Apply Now " : "તમારો પ્રશ્ન પૂછો અથવા હમણાં જ અરજી કરો"}</title>
        <meta name="description" content={selectedLanguage == "en" ? "Ask your question or Apply Now " : "તમારો પ્રશ્ન પૂછો અથવા હમણાં જ અરજી કરો"} />
        <meta name="keywords" content={selectedLanguage == "en" ? "Ask your question or Apply Now " : "તમારો પ્રશ્ન પૂછો અથવા હમણાં જ અરજી કરો"} />
      </Helmet>
      <div style={{ background: '#ffefe6' }}>
        <br />
        <div>
          <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}> {selectedLanguage == "en" ? "Ask your question or Apply Now" : "તમારો પ્રશ્ન પૂછો અથવા હમણાં જ અરજી કરો"}</p>
        </div>
        <br />
      </div>
      <div class="text-start col-xl-6 col-md-8 col-11 m-auto mt-5 "  >


        <div class="form-group">
          <label>{selectedLanguage == "en" ? "Name" : "નામ"}</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name"
            name="Name"
            value={details.Name}
            onChange={userApplyForm}
          />
        </div>

        <div class="form-group mt-2">
          <label>{selectedLanguage == "en" ? "Email" : "ઈમેલ"}</label>
          <input
            type="email"
            class="form-control"
            placeholder="kdccbank@gmail.com"
            value={details.Email}
            name="Email"
            onChange={userApplyForm}
          />
        </div>
        <label class="mt-2">{selectedLanguage == "en" ? "Phone no." : "ફોન નં."}</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Phone Number"
            maxLength={10}
            name="Phone"
            value={details.Phone}
            onChange={userApplyForm}
          />
          <span>
            <button
              class="btn-blue"
              style={{ zIndex: "0" }}
              disabled={otpBtn ? true : false}
              onClick={() => sendOtp()}
            >
              {selectedLanguage == "en" ? "Verify" : "ચકાસો"}
            </button>
          </span>
        </div>

        {!otpBtn ? (
          ""
        ) : (
          <div class="form-group mt-2">
            <input
              type="text"
              class="form-control"
              placeholder="OTP"
              name="PageName"
              value={fillOtp}
              maxLength={6}
              onChange={(e) => setFillOtp(e.target.value)}
            />
          </div>
        )}

        {/* <div class="form-group mt-2">
          <label>{selectedLanguage == "en" ? "Nearest KDCC Branch" : "નજીકની KDCC શાખા"}</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nearest KDCC Branch"
            value={details.Branch}
            name="Branch"
            onChange={userApplyForm}
          />
        </div> */}

        <div class="form-group mt-2">
        <label>{selectedLanguage == "en" ? "Nearest KDCC Branch" : "નજીકની KDCC શાખા"}</label>
          <select
            class="form-select"
            id="inputGroupSelect02"
            onChange={branchFill}
            value={details?.Branch}
          >
                <option value='' >Select Branch </option> 

            {branch && 
            
            branch.map((el)=>{
              return(
                <option  value={el?.name}>{el?.name}</option> 
              )
            })} 
          </select>
        </div>

        <div class="form-group mt-2">
          <label>{selectedLanguage == "en" ? "Select Departments " : "વિભાગો પસંદ કરો"}</label>
          <select
            class="form-select"
            id="inputGroupSelect01"
            onChange={queryFill}
          >
            <option selected value="New Account">New Account</option>
            <option value="Loan">Loan</option>
            <option value="Other Services">Other Service</option>
          </select>
        </div>

        <div class="form-group mt-2">
          <label>{selectedLanguage == "en" ? "Messages " : "સંદેશાઓ"}</label>
          <textarea
            class="form-control"
            placeholder="Leave a comment here"
            name="Comments"
            value={details.Comments}
            onChange={userApplyForm}
          ></textarea>
        </div>

        <button
          class="btn-orang form-control mt-3"
          disabled={subBtn ? true : false}
          onClick={submitDetails}
        >
          {selectedLanguage == "en" ? "Submit" : "સબમિટ કરો"}

        </button>
      </div>
    </div>

  );
};
export default ApplyNow;
